module.exports = new Promise((resolve, reject) => {
      const appLink = {
        0: {
            dev: "https://admin-console-ui-config-wlwij2koxa-ue.a.run.app",
            stg: "https://admin-console-ui-config-dfuy5txq2a-ue.a.run.app",
            uat: "https://admin-console-ui-config-5z4yognwxq-ue.a.run.app",
            prd: "https://admin-console-ui-config-hkq4xbkz6q-ue.a.run.app",
        },
        2: {
            dev: "https://ac-ui-tenant-admin-wlwij2koxa-ue.a.run.app/",
            stg: "https://ac-ui-tenant-admin-dfuy5txq2a-ue.a.run.app",
            uat: "https://ac-ui-tenant-admin-5z4yognwxq-ue.a.run.app",
            prd: "https://ac-ui-tenant-admin-hkq4xbkz6q-ue.a.run.app",
        },
        3: {
          dev: "https://ac-ui-users-permissions-wlwij2koxa-ue.a.run.app",
          stg: "https://ac-ui-users-permissions-dfuy5txq2a-ue.a.run.app",
          uat: "https://ac-ui-users-permissions-5z4yognwxq-ue.a.run.app",
          prd: "https://ac-ui-users-permissions-hkq4xbkz6q-ue.a.run.app",
      },
      1: {
        dev: "https://ac-ui-program-insights-wlwij2koxa-ue.a.run.app",
        stg: "https://ac-ui-program-insights-dfuy5txq2a-ue.a.run.app",
        uat: "https://ac-ui-program-insights-5z4yognwxq-ue.a.run.app",
        prd: "https://ac-ui-program-insights-hkq4xbkz6q-ue.a.run.app",
    },
      };
    const hostname = window.location.hostname;
    let remoteUrl = '';
    let env = ""
    if (hostname.includes('local')) {
      remoteUrl = "http://" + hostname;
      remoteUrl += ':3001';
    }else if (hostname.includes('admin-console-client-wlwij2koxa-ue.a.run.app') || hostname.includes('console.dev.rotw.uplight.io')){
      env = "dev";
    }else if (hostname.includes('admin-console-client-dfuy5txq2a-ue.a.run.app') || hostname.includes('console.stg.rotw.uplight.io')){
      env = "stg";
    }else if (hostname.includes('admin-console-client-5z4yognwxq-ue.a.run.app') || hostname.includes('console.uat.rotw.uplight.io')){
      env = "uat";
    }else {
      env = "prd";
    }
    if (env) {
      remoteUrl = appLink[0][env];
    }
    remoteUrl += '/config_uiRemoteEntry.js'
    const script = document.createElement('script')
    script.src = remoteUrl
    script.onerror = () => {
    console.error('Failed to load remote script: ' + remoteUrl);
      reject(new Error('config_ui is currently unavailable. Please try again later.'));
    };
    script.onload = () => {
      const proxy = {
        get: (request) => window.config_ui.get(request),
        init: (arg) => {
          try {
            return window.config_ui.init(arg)
          } catch(e) {
            console.log('config_ui remote container already initialized')
          }
        }
      }
      resolve(proxy)
    }
    document.head.appendChild(script);
  })
  ;